import {Controller} from "@hotwired/stimulus"
import { postFormWithQueryParams } from "../common/globalFunctions";

// Connects to data-controller="master-groups"
export default class extends Controller {

  connect() {
  }

  csvDownload(e) {
    e.preventDefault();
    postFormWithQueryParams('/master/groups/export_logs');
  }
}
